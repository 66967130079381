import React from "react"
import 'twin.macro'


const Btn = ({link, text, target}) => {



return(


<a href={link}><button tw="rounded bg-cygreen px-5 py-4 text-white font-bold transition duration-300 ease-in-out transform hover:shadow-md hover:bg-cydarkgreen hover:-translate-y-1">{text}</button></a>

)
}

export default Btn
