import React from 'react'
import Img from 'gatsby-image'
import 'twin.macro'

import Btn from './button.js'

const CourseCard = ({headline, cta_link, cta_title, copy, image, image_title}) => {

  return (
    <div tw="rounded max-w-5xl bg-cylightblue bg-opacity-25 border border-cylightblue my-10 mx-auto pb-10 md:px-6 xl:px-32 flex-col flex items-center">
      <div tw="px-3 py-4">
        <h2 tw="text-center">{headline}</h2>
        <Img fluid={image} alt={image_title} tw="mb-5" />
        <section dangerouslySetInnerHTML={{__html: copy}} />
      </div>
        <Btn link={cta_link} text={cta_title} tw="items-center" />
    </div>




  )

}

export default CourseCard

