import React from 'react'
import { Link, graphql } from 'gatsby'
import tw from 'twin.macro'

import Layout from '../components/layout'
import styles from '../utils/index.css'
import CourseCard from '../components/course_card'
import SEO from "../components/seo"


const Courses = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title
  const course = data.course.edges
  const intro = data.intro

  return(
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
    <SEO title={intro.headline} description={data.metaDescription.metaData} />
      <h1 tw="text-center">{intro.headline}</h1>
      <p tw="md:w-5/6 text-center mx-auto">{intro.copy.copy}</p>

      {course && course.map(({node}) => {
       return (
        <div id="courses" key={node.id}>
          <CourseCard headline={node.headline} copy={node.copy.childMarkdownRemark.html} cta_link={node.ctaLink} cta_title={node.ctaTitle} image={node.featuredImage.fluid} image_title={node.featuredImage.description} />
        </div>
        )
      }
      )}
    </Layout>
  )
}

export default Courses

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaDescription: contentfulSeoMetadata(title: {eq: "Courses > Meta Description"}) {
      metaData
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    course:allContentfulLayoutCopy(filter: {title: {regex: "/^Courses > ((?!Intro).)*$/"}, node_locale: {eq: "en-US"}}, sort: {fields: order, order: ASC}) {
      edges {
        node {
          id
          title
          copy {
            childMarkdownRemark {
              html
            }
          }
          ctaLink
          ctaTitle
          headline
          featuredImage {
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    intro:contentfulLayoutCopy(title: {eq: "Courses > Intro"}) {
      title
      copy {
        copy
      }
      headline
    }
  }
`
